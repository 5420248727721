<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-header">
                                     <h4 class="card-title">{{ $t('add-spot') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('category*')" label-for="Category">
                                                <b-form-select :options="categories" value-field="id" text-field="name" v-model="$v.form.category_id.$model" @change="getSelectedSpotCategory($event)" :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null" aria-describedby="input-2-live-feedback">
                                                    <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option>
                                                </b-form-select>
                                                <b-form-invalid-feedback v-if="!$v.form.category_id.required" id="input-2-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('user*')" label-for="name">
                                                <b-form-select v-model="$v.form.user_id.$model" :state="$v.form.user_id.$dirty ? !$v.form.user_id.$error : null" aria-describedby="input-1-live-feedback" :options="users" value-field="id" text-field="name">
                                                    <b-form-select-option v-if="users.length === 0" :disabled = true>{{ $t('no-user-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                   {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group id="spot_date" :label="$t('spot-date')" label-for="spot_date">
                                                <b-form-input id="spot_date" type="datetime-local" v-model="form.spot_date"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('latitude*')" label-for="latitude">
                                                <b-form-input type="text" id="latitude" autocomplete="new-password" :placeholder="$t('latitude')"  v-model="$v.form.latitude.$model" :state="$v.form.latitude.$dirty ? !$v.form.latitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                  <b-form-invalid-feedback v-if="!$v.form.latitude.required"
                                                             id="input-1-live-feedback">{{ $t("field-required") }}
                                                  </b-form-invalid-feedback>
                                                  <b-form-invalid-feedback v-if="!$v.form.latitude.between"
                                                             id="input-2-live-feedback">
                                                   {{ $t("Value must be between -90 and 90") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('longitude*')" label-for="longitude">
                                                <b-form-input type="text" id="longitude" autocomplete="new-password" :placeholder="$t('longitude')"  v-model="$v.form.longitude.$model" :state="$v.form.longitude.$dirty ? !$v.form.longitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                                    <b-form-invalid-feedback v-if="!$v.form.longitude.required"
                                                        id="input-1-live-feedback">{{ $t("field-required") }}
                                                    </b-form-invalid-feedback>
                                                    <b-form-invalid-feedback v-if="!$v.form.longitude.between"
                                                             id="input-2-live-feedback">
                                                        {{ $t("Value must be between -180 and 180") }}
                                                    </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('spot-type*')" label-for="name">
                                                <b-form-select v-model="$v.form.spot_type_id.$model" :state="$v.form.spot_type_id.$dirty ? !$v.form.spot_type_id.$error : null" aria-describedby="input-1-live-feedback" :options="spot_types"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="spot_types.length === 0" :disabled = true>{{ $t('no-spot-type-found') }}</b-form-select-option></b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('location*')" label-for="name">
                                                <b-form-select  @change="selectFixLocation($event)" v-model="$v.form.location_id.$model" :state="$v.form.location_id.$dirty ? !$v.form.location_id.$error : null" aria-describedby="input-1-live-feedback" :options="locations"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="locations.length === 0" :disabled = true>{{ $t('no-location-found') }}</b-form-select-option>
                                                </b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group :label="$t('fix-location')" label-for="fix_location_id">
                                                <b-form-select @change="getFixLocation($event)"  v-model="form.fix_location_id" aria-describedby="input-1-live-feedback" :options="fixLocations"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="fixLocations.length === 0" :disabled = true>{{ $t('no-fix-location-found') }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('details')" label-for="name">
                                                <b-form-textarea v-model="form.detail"></b-form-textarea>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="col-md-4 pl-0">
                                                <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('approve-status') }}</label>
                                                <br>
                                                <toggle-button v-model="form.status" :sync="true" class="switchColor" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="col-md-4 pl-0">
                                                <label class="mr-sm-2 ml-0 pl-0" for="inline-form-custom-select-pref">{{ $t('spot-shareable') }}</label>
                                                <br>
                                                <toggle-button v-model="form.is_shareable" :sync="true" class="switchColor" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <b-form-group :label="$t('add-images-or-acoustic-data')" label-for="name">
                                    </b-form-group>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType0">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(0)"></i></a>
                                                </div>
                                                <img :src="form.url" alt class="mb-1" v-if="imageType0 === 'image'">
                                                <audio controls :src="form.url" v-if="imageType0 === 'audio'" @play="playAudio(0)" ref="audio0"></audio>
                                            </div>
                                            <!-- working -->
                                            <b-form-group label label-for="input8">
                                                <b-form-file ref="imageInput" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input8" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-image')" @change="onFileSelected($event, 'image')"></b-form-file>
                                                <b-form-file ref="audioInput" class="mt-2" accept=".mp3,audio/*" id="inputaudio8" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected($event, 'audio')"></b-form-file>
                                                <div v-if="imageError" class="custom-invalid-feedback" id="input-2-live-feedback">
                                                    {{ $t("field-required") }}
                                                </div>
                                                <!-- <div v-if="imageSizeError" class="custom-invalid-feedback" id="input-9-live-feedback"> {{  $t('image-size-must-be-less-than-5mb') }}</div> -->
                                                <div v-if="imageSizeError" class="custom-invalid-feedback" id="input-9-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div> 
                                                <div v-if="audioSizeError && this.version_id === 1" class="custom-invalid-feedback"
                                                 id="input-9-live-feedback">
                                                 {{ $t('audio-size-must-be-less-than-3mb')}}
                                                </div>
                                                <div v-if="this.version_id !== 1 && audioSizeError" class="custom-invalid-feedback"
                                                 id="input-9-live-feedback">
                                                 {{ $t('audio-size-must-be-less-than-10mb') }}
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType1">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(1)"></i></a>
                                                </div>
                                                <img :src="form.url1" alt class="mb-1" v-if="imageType1 === 'image'">
                                                <audio controls :src="form.url1" v-if="imageType1 === 'audio'" @play="playAudio(1)" ref="audio1"></audio>                                               
                                            </div>
                                            <b-form-group label label-for="input8">
                                                <b-form-file ref="image1Input" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input" :placeholder="$t('upload-image')" @change="onFileSelected1($event, 'image')"></b-form-file>
                                                <b-form-file ref="audio1Input" class="mt-2" accept=".mp3,audio/*" id="inputaudio9" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected1($event, 'audio')"></b-form-file>
                                            </b-form-group>
                                            <div v-if="imageSizeError1" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div>
                                            <div v-if="audioSizeError1 && this.version_id === 1" class="custom-invalid-feedback" id="input-10-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                            <div v-if="this.version_id !== 1 && audioSizeError1" class="custom-invalid-feedback"
                                                id="input-9-live-feedback">
                                                {{ $t('audio-size-must-be-less-than-10mb') }}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="upload-img">
                                                <div class="del-icon" v-if="imageType2">
                                                    <a class="container1"><i class="fas fa-trash" @click="removeImage(2)"></i></a>
                                                </div>
                                                <img :src="form.url2" alt class="mb-1" v-if="imageType2 === 'image'">
                                                <audio controls :src="form.url2" v-if="imageType2 === 'audio'" @play="playAudio(2)" ref="audio2"></audio>
                                            </div>
                                            <b-form-group label label-for="input10">
                                                <b-form-file ref="image2Input" class="mt-2" accept="image/png, image/jpeg, image/bmp" id="input10" :placeholder="$t('upload-image')" @change="onFileSelected2($event, 'image')"></b-form-file>
                                                <b-form-file ref="audio2Input" class="mt-2" accept=".mp3,audio/*" id="inputaudio10" :class="{'is-invalid': this.imageError}" :placeholder="$t('upload-audio')" @change="onFileSelected2($event, 'audio')"></b-form-file>
                                            </b-form-group>
                                            <div v-if="imageSizeError2" class="custom-invalid-feedback" id="input-11-live-feedback">{{ $t('image-size-must-be-less-than-3mb') }}.</div>
                                            <div v-if="audioSizeError2 && this.version_id === 1" class="custom-invalid-feedback" id="input-11-live-feedback">{{ $t('audio-size-must-be-less-than-3mb')}}</div>
                                            <div v-if="this.version_id !== 1 && audioSizeError2" class="custom-invalid-feedback"
                                                id="input-9-live-feedback">
                                                {{ $t('audio-size-must-be-less-than-10mb') }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <vue-tabs active-tab-color="#9c27b0" active-text-color="white" type="pills">
                                        <v-tab :title="$t('data-collection-questions')" icon="" class="edit-tab-body">
                                            <div class="row mt-2">
                                                <div class="col-md-12" v-for="category in form.questions" :key="category.id">
                                                    <h5>{{category.category.name}}</h5>
                                                    <div v-for="question in category.questions" :key="question.question">
                                                        <div class="row mt-2">
                                                            <div class="col-md-6">{{question.question}}</div>
                                                            <div class="col-md-6" v-if="question.responses">
                                                                <b-form-select v-model="question.answer"
                                                                    :options="question.responses" class="custom-select "
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && questionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && questionError !== true }">
                                                                </b-form-select>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6" v-else>
                                                                <b-form-input type="text" placeholder v-model="question.answer"
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && questionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && questionError !== true }">
                                                                </b-form-input>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="question.answer === 'Yes' && question.subQuestions">
                                                            <div class="row mt-2" v-for="sub_question in question.subQuestions" :key="sub_question.id">
                                                                <div class="col-md-6">{{sub_question.question}}</div>
                                                                <div class="col-md-6" v-if="sub_question.answer_type == 'D'">
                                                                    <b-form-select v-model="sub_question.answer" :options="sub_question.responses" class="custom-select"></b-form-select>
                                                                </div>
                                                                <div class="col-md-6" v-if="sub_question.answer_type == 'I'">
                                                                    <b-form-input type="text" id="sub_question_response" placeholder v-model="sub_question.answer"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-tab>
                                        <v-tab :title="$t('post-process-data')" class="edit-tab-body">
                                            <div class="row" v-if="form.ppd_questions.length > 0">
                                                <div class="col-md-12" v-for="question in form.ppd_questions" :key="question.id">
                                                    <div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-6">{{question.question}}</div>
                                                            <div class="col-md-6" v-if="question.responses.length !== 0">
                                                                <b-form-select v-model="question.answer"
                                                                    :options="question.responses" class="custom-select "
                                                                    value-field="name" text-field="name"
                                                                    :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && ppdQuestionError !== true }">
                                                                </b-form-select>
                                                                <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true"
                                                                    class="custom-invalid-feedback"
                                                                    id="input-2-live-feedback">
                                                                    {{ $t("field-required") }}
                                                                </div>
                                                            </div>
                                                        <div class="col-md-6" v-else>
                                                            <b-form-input type="text" placeholder v-model="question.answer"
                                                                :class="{ 'is-invalid' : (question.answer === '' || question.answer === null) && question.required === 1 && ppdQuestionError === true, 'is-valid':question.answer !== '' && question.answer !== null && question.required === 1 && ppdQuestionError !== true }">
                                                            </b-form-input>
                                                            <div v-if="(question.answer === '' || question.answer === null) && question.required === 1 && questionError === true"
                                                                class="custom-invalid-feedback"
                                                                id="input-2-live-feedback">
                                                                {{ $t("field-required") }}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="question.answer === 'Yes' && question.sub_questions">
                                                        <div class="row mt-2" v-for="sub_question in question.sub_questions" :key="sub_question.id">
                                                            <div class="col-md-6">{{sub_question.question}}</div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'D'">
                                                                <b-form-select v-model="sub_question.answer" :options="sub_question.sub_questions_response" value-field="name" text-field="name" class="custom-select"></b-form-select>
                                                            </div>
                                                            <div class="col-md-6" v-if="sub_question.answer_type === 'I'">
                                                                <b-form-input type="text" id="sub_question_response" placeholder v-model="sub_question.answer"></b-form-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-tab>
                                    </vue-tabs>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <!-- :disabled="isDisabled" -->
                                            <b-button :disabled="isLoading2" type="submit" variant="success"  class="btn float-right btn-success">
                                                <template v-if="isLoading2">
                                                    <i class="fas fa-spinner fa-spin"></i>{{$t('submitting')}}...
                                                </template>
                                                <template v-else>
                                                    <span>{{ $t('submit-btn') }}</span>
                                                </template>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>
<script>
import Vue from "vue";
import API from "@/api";
import Snotify, {
    SnotifyPosition
} from "vue-snotify";
import {
    validationMixin
} from "vuelidate";
import moment from "moment";
import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
import Tinybox from "vue-tinybox";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
const options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
};
Vue.use(Snotify, options);
Vue.component("Tinybox", Tinybox);
Vue.component("EnlargeableImage", EnlargeableImage);
import Compressor from 'compressorjs';
// const MAX_SIZE = 104857600;
const MAX_SIZE = 10485760;
const MAX_SIZE_CS_VERSION = 3145728;
export default {
    name: "Users",
    mixins: [validationMixin],
    data() {
        return {
            currentAudioIndex: -1,
            version_id:null,
            form: {
                id: "",
                client_id: JSON.parse(localStorage.getItem("client_id")),
                client_url: "",
                category_id: "",
                user_id: "",
                spot_type_id: "",
                image: [],
                image1: [],
                image2: [],
                media_types: [],
                url: "",
                url1: "",
                url2: "",
                primary_image: "none",
                latitude: "",
                longitude: "",
                location_id: "",
                fix_location_id: "",
                spot_date: "",
                detail: "",
                questions: [],
                ppd_questions: [],
                status: true,
                is_shareable:true,
                from_admin: true
                // selectedTab: 'Data Collection Questions',
            },
            training_status: {
                0: "No",
                1: "Yes"
            },
            spot_type: "",
            images: [],
            imageError: "",
            imageSizeError: false,
            audioSizeError: false,
            imageSizeError1: false,
            audioSizeError1: false,
            imageSizeError2: false,
            audioSizeError2: false,
            isLoading2:false,
            index: "",
            isDisabled: false,
            readonly: true,
            showPassword: true,
            showPassword1: true,
            inputType: "password",
            inputType1: "password",
            locations: [],
            fixLocations: [],
            spot_types: [],
            users: [],
            categories: [],
            client_id: JSON.parse(localStorage.getItem("client_id")),
            imageType0: '',
            imageType1: '',
            imageType2: '',
            questionError: false,
            ppdQuestionError: false
        };
    },
    validations: {
        form: {
            category_id: {
                required
            },
            user_id: {
                required
            },
            latitude: {
                required,
                 between(value) {
                        return between(-90, 90)(value)
                    }
            },
            longitude: {
                   required,
                    between(value) {
                        return between(-180, 180)(value)
                    }
            },
            location_id: {
                required
            },
            spot_type_id: {
                required
            }
        }
    },
    beforeCreate() {},
    beforeMount() {
        // this.getSpot();
    },
    async mounted() {
        var date_time = moment().format("YYYY-MM-DDTHH:mm");
        this.form.spot_date = date_time;
        this.spot_type = "S";
        await this.initializeData();
        this.getClientUsers();
        if (this.spot_type === "S") {
            this.getClientLocations();
            this.getUserSpotTypes();
            this.getPostDataQuestions();
        } else {
            this.getClientAcousticLocations();
            this.getAcousticUserSpotTypes();
        }
    },
  created() {
    this.getAppSettings();
  },
    updated() {
        // this.form.questions.push();
    },
    methods: {
            playAudio(index) {
                this.audioPlayer = this.$refs.audioPlayer;
                if (this.audioPlayer) {
                    this.audioPlayer.play();
                    this.audioPlayer.addEventListener('timeupdate', this.updateCurrentTime);
                    this.audioPlayer.addEventListener('durationchange', this.updateAudioDuration);
                }
            },
            pauseAudio(index) {
                if (this.audioPlayer) {
                    this.audioPlayer.pause();
                }
            },
            seekAudio() {
                if (this.audioPlayer) {
                    this.audioPlayer.currentTime = this.currentTime;
                }
            },
            updateCurrentTime() {
                this.currentTime = this.audioPlayer.currentTime;
            },
            updateAudioDuration() {
                this.audioDuration = this.audioPlayer.duration;
            },
            removeImage(index) {
                if (index === 0) {
                    this.form.url = '';
                    this.imageType0 = '';
                    this.form.image = '';
                    this.imageError = true;
                    this.form.media_types[0] = '';
                    this.$refs.imageInput.reset();
                    this.$refs.audioInput.reset();
                    this.getAppSettings();
                } else if (index === 1) {
                    this.form.url1 = '';
                    this.imageType1 = '';
                    this.form.image1 = '';
                    this.form.media_types[1] = '';
                    this.$refs.image1Input.reset();
                    this.$refs.audio1Input.reset();
                } else if (index === 2) {
                    this.form.url2 = '';
                    this.imageType2 = '';
                    this.form.image2 = '';
                    this.form.media_types[2] = '';
                    this.$refs.image2Input.reset();
                    this.$refs.audio2Input.reset();
                }
                // this.$snotify.success("Image removed successfully");
            },
            getFixLocation(e) {
                const data = {
                    id: e,
                };
                API.getFixLocation(
                    data,
                    data => {
                        this.form.longitude = data.longitude;
                        this.form.latitude = data.latitude;
                    },
                    err => {}
                );
            },
            selectFixLocation(e) {
                const data = {
                    location_id: e,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.fixLocationsDropdown(
                    data,
                    data => {
                        if(data.status === 200) {
                            this.fixLocations = data.data;
                        }
                    },
                    err => {}
                )
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        // this.imageError = false
                        this.imageError = (data.data.require_photo === 1) ? 'true' : '';              
                    },
                    err => {
                    }
                )
            },
        onSubmit() {
            this.$v.form.$touch();
            var questionErr = false;
            var ppdQuestionError = false;
            this.form.questions.forEach(function(category, index) {
                category.questions.forEach(function(question, i) {
                    if(question.answer === '' || question.answer === null && question.required === 1){
                        questionErr = true;
                    }
                })
            });

            this.form.ppd_questions.forEach(function(question, i) {
                if(question.answer === '' || question.answer === null && question.required === 1){
                    ppdQuestionError = true;
                }
            });
            if(ppdQuestionError){
                    this.ppdQuestionError = true;
                    // this.selectedTab = 'Post Process Data';
                    this.$snotify.error('Post Process Data answer fields missing', {
                    timeout: 1000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true 
                });
                return;
            }
            if(questionErr || ppdQuestionError){
                this.questionError = true;
                this.ppdQuestionError = true;
                return;
            }

            if (this.$v.form.$anyError) {
                return;
            }
            if(this.imageError == "true" && this.form.url == '') {
                return;
            }

          
            const fd = new FormData();
                if (this.form.image !== undefined && this.form.image.length !== 0) {
                    fd.append('image', this.form.image, this.form.image.name)
                }

                if (this.form.image1 !== undefined && this.form.image1.length !== 0) {
                    fd.append('image1', this.form.image1, this.form.image1.name)
                }

                if (this.form.image2 !== undefined && this.form.image2.length !== 0) {
                    fd.append('image2', this.form.image2, this.form.image2.name)
                }
                fd.append('category_id', this.form.category_id);
                fd.append('user_id', this.form.user_id);
                fd.append('spot_type_id', this.form.spot_type_id);
                fd.append('location_id', this.form.location_id);
                fd.append('fix_location_id', this.form.fix_location_id);
                fd.append('detail', this.form.detail);
                fd.append('spot_date', this.form.spot_date);
                // fd.append('primary_image', this.form.primary_image, this.form.primary_image.name);
                fd.append('media_types', JSON.stringify(this.form.media_types));
                fd.append('status', this.form.status);
                fd.append('is_shareable', this.form.is_shareable);
                fd.append('from_admin', this.form.from_admin);

                if (this.form.latitude === '' || this.form.latitude === null) {
                    fd.append('latitude', this.latitude);
                } else {
                    fd.append('latitude', this.form.latitude);
                }

                if (this.form.longitude === '' || this.form.longitude === null) {
                    fd.append('longitude', this.longitude);
                } else {

                    fd.append('longitude', this.form.longitude);
                }

                fd.append('questions', JSON.stringify(this.form.questions));
                fd.append('ppd_questions', JSON.stringify(this.form.ppd_questions));
                fd.append('client_id', this.form.client_id);
                fd.append('client_url', this.form.client_url);
                this.isDisabled = true;
                this.isLoading2 = true;
            API.addSpot(
                fd,
                data => {
                    this.isDisabled = false;
                    this.isLoading2 = false;
                    if (data.status === 200) {
                        this.$snotify.success(data.message, {
                            timeout: 1000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true
                        })
                        .on("destroyed", () => this.$router.push({ name: "spots" }));
                    }
                    else if (data.status === 410) {
                        if (data.data.image) {
                            this.imageSizeError = true;
                        }
                        if (data.data.image1) {
                            this.imageSizeError1 = true;
                        }
                        if (data.data.image2) {
                            this.imageSizeError2 = true;
                        }
                    }
                    else if(data.status === 500) {
                        this.$snotify.error(data.message)
                        this.form.fix_location_id = ''
                    }
                },
                err => {
                    this.isLoading2 = false;
                }
            );
        },
        async initializeData() {
            let data = {
                id: this.client_id
            };
            await API.get_client(
                data,
                data => {
                    this.version_id = data.version_id;
                    this.form.client_url = data.url;
                    this.getAddSpotCategories();
                },
                err => {}
            );
        },
        getClientUsers() {
            const data = {
                client_id: JSON.parse(localStorage.getItem("client_id"))
            };
            API.getClientUsers(
                data,
                data => {
                    this.users = data.data;
                },
                err => {}
            );
        },
        getClientLocations() {
            const data = {
                client_id: JSON.parse(localStorage.getItem("client_id"))
            };
            API.getClientLocations(
                data,
                data => {
                    this.locations = data.data;
                },
                err => {}
            );
        },
        getClientAcousticLocations() {
            const data = {
                client_id: JSON.parse(localStorage.getItem("client_id"))
            };
            API.getClientAcousticLocations(
                data,
                data => {
                    this.locations = data.data;
                },
                err => {}
            );
        },
        getUserSpotTypes() {
            const data = {
                client_id: JSON.parse(localStorage.getItem("client_id"))
            };
            API.getUserSpotTypes(
                data,
                data => {
                    this.spot_types = data.data;
                },
                err => {}
            );
        },
        getAcousticUserSpotTypes() {
            const data = {
                client_id: JSON.parse(localStorage.getItem("client_id"))
            };
            API.getAcousticUserSpotTypes(
                data,
                data => {
                    this.spot_types = data.data;
                },
                err => {}
            );
        },
        getAddSpotCategories() {
            const data = {
                client_url: this.form.client_url
            };
            API.getAddSpotCategories(
                data,
                data => {
                    this.categories = data.data;
                },
                err => {}
            );
        },
        getSelectedSpotCategory() {
            const data = {
                id: this.form.id,
                type: this.spot_type,
                client_id: this.client_id,
                category_id: this.form.category_id
            };
            API.getSelectedSpotCategory(
                data,
                data => {
                    this.form.questions = data.new_array;
                },
                err => {}
            );
        },
        getPostDataQuestions() {
            const data = {
                client_id: this.client_id,
            };
            API.getPostDataQuestions(
                data,
                data => {
                    this.form.ppd_questions = data.data;
                },
                err => {}
            );
        },
        compressImage(file) {
            const maxWidth = 1000;
            const maxHeight = 1000;
            const quality = 0.8;
            const mimeType = file.type;
            return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth,
                maxHeight,
                quality,
                mimeType,
                success(result) {
                resolve(result);
                },
                error(error) {
                reject(error);
                },
            });
            });
        },
        async onFileSelected(event, type) {
            this.version_id;
            this.imageType0 = type;
            this.form.media_types[0] = type;
            if(this.form.media_types[0] === 'audio'){
                this.$refs.imageInput.reset();
            }else{
                this.$refs.audioInput.reset();

            }
            if(this.form.media_types[0] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                        const compressedImage = await this.compressImage(event.target.files[0]);
                        this.form.image = compressedImage;
                        this.form.url = URL.createObjectURL(compressedImage);
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;  
                }
                else{
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image = event.target.files[0];
                        this.form.url = URL.createObjectURL(this.form.image);
                        this.imageSizeError = false;
                        this.audioSizeError = false;
                        this.imageError = false;
                    }
                    else{
                        this.imageSizeError = true;
                    }
                }
            }
            else {
                 if(this.form.media_types[0] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image = event.target.files[0];
                            this.form.url = URL.createObjectURL(this.form.image);
                            this.imageSizeError = false;
                            this.audioSizeError = false;
                            this.imageError = false;
                        }
                        else{
                            this.audioSizeError = true;
                        }
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                            this.form.image = event.target.files[0];
                            this.form.url = URL.createObjectURL(this.form.image);
                            this.imageSizeError = false;
                            this.audioSizeError = false;
                            this.imageError = false;
                        } 
                        // this.imageError = false;
                        else{
                        this.audioSizeError = true;
                        // this.imageError = false;
                        }
                    }
                       
                    }
                    else{
                        this.imageSizeError = true;
                        // this.imageError = false;
                    }
                       // this.imageSizeError = true;
                }
        },
        async onFileSelected1(event, type) {
            this.version_id;
            this.imageType1 = type;
            this.form.media_types[1] = type;
            if(this.form.media_types[1] === 'audio'){
                 this.$refs.image1Input.reset();
            }else{
                 this.$refs.audio1Input.reset();
            }
            if(this.form.media_types[1] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                    const compressedImage = await this.compressImage(event.target.files[0]);
                    this.form.image1 = compressedImage;
                    this.form.url1 = URL.createObjectURL(compressedImage);
                    this.imageSizeError1 = false;
                    this.audioSizeError1 = false;
                }
                else{
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image1 = event.target.files[0];
                        this.form.url1 = URL.createObjectURL(this.form.image1);
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                        this.imageError1 = false;
                    }
                    else{
                        this.imageSizeError1 = true;
                    }
                } 
            }
            else {
                 if(this.form.media_types[1] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image1 = event.target.files[0];
                            this.form.url1 = URL.createObjectURL(this.form.image1);
                            this.imageSizeError1 = false;
                            this.audioSizeError1 = false;
                            this.imageError1 = false;
                        }
                        else{
                            this.audioSizeError1 = true;
                        }
                    }
                    else{
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image1 = event.target.files[0];
                        this.form.url1 = URL.createObjectURL(this.form.image1);
                        this.imageSizeError1 = false;
                        this.audioSizeError1 = false;
                        this.imageError1 = false;
                        } 
                        else{
                            this.audioSizeError1 = true;
                        }
                    }
                }
                    // else{
                    //     this.imageSizeError1 = true;
                    // }
                // this.imageSizeError1 = true;
            }
        },
        async onFileSelected2(event, type) {
            this.version_id;
            this.imageType2 = type;
            this.form.media_types[2] = type;
            if(this.form.media_types[2] === 'audio'){
                this.$refs.image2Input.reset();
            }else{
                this.$refs.audio2Input.reset();
            }
            if(this.form.media_types[2] === 'image'){
                if(this.version_id === 2 || this.version_id === 3){
                    const compressedImage = await this.compressImage(event.target.files[0]);
                    this.form.image2 = compressedImage;
                    this.form.url2 = URL.createObjectURL(compressedImage);
                    this.imageSizeError2 = false;
                    this.audioSizeError2 = false;
                }
                else {
                    if(event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                        this.form.image2 = event.target.files[0];
                        this.form.url2 = URL.createObjectURL(this.form.image2);
                        this.imageSizeError2 = false;
                        this.audioSizeError2 = false;
                        this.imageError2 = false;
                    }
                    else{
                        this.imageSizeError2 = true;
                    }
                }
            }
            else {
                if(this.form.media_types[2] === 'audio'){
                    if(this.version_id === 2 || this.version_id === 3){
                        if (event.target.files[0].size <= MAX_SIZE) {
                            this.form.image2 = event.target.files[0];
                            this.form.url2 = URL.createObjectURL(this.form.image2);
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false;
                            this.imageError2 = false;
                        }
                        else{
                            this.audioSizeError2 = true;
                        }
                    }
                    else {
                        if (event.target.files[0].size <= MAX_SIZE_CS_VERSION) {
                            this.form.image2 = event.target.files[0];
                            this.form.url2 = URL.createObjectURL(this.form.image2);
                            this.imageSizeError2 = false;
                            this.audioSizeError2 = false;
                            this.imageError2 = false;
                        } 
                        else{
                            this.audioSizeError2 = true;
                        }
                    }   
                }
                    // else{
                    //     this.imageSizeError2 = true;
                    // }
                // this.imageSizeError2 = true;
            }
        },
        setAsPrimary(id){
        }
    },
    computed: {}
};
</script>
<style scoped>
/* your passed-in element */
.enlargeable-image>.slot {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;
}
/* default img if no element passed in */
.enlargeable-image>.slot>img.default {
    max-width: 100%;
    vertical-align: middle;
}
/* passed-in element when growth is happening */
.enlargeable-image.active>.slot {
    opacity: 0.3;
    filter: grayscale(100%);
}
/* full version that grows (background image allows seamless transition from thumbnail to full) */
.enlargeable-image .full {
    cursor: zoom-out;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
}
.enlargeable-image .full>img {
    object-fit: contain;
    width: 0;
    height: 0;
    display: none !important;
}
/* full version while getting bigger */
.enlargeable-image .full.enlarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-out;
    z-index: 3;
}
/* full version while at its peak size */
.enlargeable-image .full.enlarged {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-out;
    z-index: 2;
}
/* full version while getting smaller */
.enlargeable-image .full.delarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-in;
    z-index: 1;
}
.enlargeable-image .full.enlarged img {
    display: none !important;
    background-color: red;
    width: 100%;
    height: 100%;
}
.row .card .new-card-body {
    padding: 1.25rem 1.437rem !important;
}
.edit-tab-body {
    margin: 0px;
}
@media (max-width: 375px) {
    .row .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
</style>
